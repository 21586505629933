var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { flow, set, transformOptions, isNumber, get, fieldAdapter } from '../../utils';
import { mark } from '../../adaptor';
/**
 * @param chart
 * @param options
 */
export function adaptor(params) {
    /**
     * coordinate 配置
     * @param params
     */
    var coordinate = function (params) {
        var options = params.options;
        var startAngle = options.startAngle, maxAngle = options.maxAngle, coordinate = options.coordinate;
        // 默认开始角度是-90度
        var start = isNumber(startAngle) ? (startAngle / (2 * Math.PI)) * 360 : -90;
        // 结束角度通过maxAngle来计算
        var end = isNumber(maxAngle) ? ((Number(maxAngle) + start) / 180) * Math.PI : Math.PI;
        set(params, ['options', 'coordinate'], __assign(__assign({}, coordinate), { endAngle: end, startAngle: startAngle !== null && startAngle !== void 0 ? startAngle : -Math.PI / 2 }));
        return params;
    };
    /**
     * tooltip 配置
     * @param params
     */
    var tooltip = function (params) {
        var options = params.options;
        var tooltip = options.tooltip, xField = options.xField, yField = options.yField;
        var getXFieldData = fieldAdapter(xField);
        var getYFieldData = fieldAdapter(yField);
        if (!tooltip) {
            set(options, 'tooltip', {
                title: false,
                items: [function (d, i, data) { return ({ name: getXFieldData(d, i, data), value: getYFieldData(d, i, data) }); }],
            });
        }
        return params;
    };
    /**
     * background 配置
     * @param params
     */
    var background = function (params) {
        var options = params.options;
        var markBackground = options.markBackground, children = options.children, scale = options.scale, coordinate = options.coordinate, xField = options.xField;
        var domain = get(scale, 'y.domain', []);
        if (markBackground) {
            children.unshift(__assign({ type: 'interval', xField: xField, yField: domain[domain.length - 1], colorField: markBackground.color, scale: { color: { type: 'identity' } }, style: {
                    fillOpacity: markBackground.opacity,
                    fill: markBackground.color ? undefined : '#e0e4ee', // 默认用fill填满灰色背景
                }, 
                // 背景图需要填满整个圆
                coordinate: __assign(__assign({}, coordinate), { startAngle: -Math.PI / 2, endAngle: (3 / 2) * Math.PI }), animate: false }, markBackground));
        }
        return params;
    };
    return flow(coordinate, tooltip, background, mark, transformOptions)(params);
}
