import { mark } from '../../adaptor';
import { flow, get, isArray, set, transformOptions } from '../../utils';
/**
 * @param chart
 * @param options
 */
export function adaptor(params) {
    var dataTransform = function (params) {
        var options = params.options;
        var data = options.data;
        var defaultTransform = [
            {
                type: 'custom',
                callback: function (datum) { return ({ links: datum }); },
            },
        ];
        if (isArray(data)) {
            if (data.length > 0) {
                set(options, 'data', {
                    value: data,
                    transform: defaultTransform,
                });
            }
            else {
                delete options.children;
            }
        }
        else if (get(data, 'type') === 'fetch' && get(data, 'value')) {
            var transform = get(data, 'transform');
            if (isArray(transform)) {
                set(data, 'transform', transform.concat(defaultTransform));
            }
            else {
                set(data, 'transform', defaultTransform);
            }
        }
        return params;
    };
    return flow(dataTransform, mark, transformOptions)(params);
}
