var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useRef, useEffect } from 'react';
import { isFunction, isEqual, createNode, cloneDeep, isArray, isObject, isValidElement } from '../util';
export default function useChart(ChartClass, config) {
    var chart = useRef();
    var chartOptions = useRef();
    var container = useRef(null);
    var onReady = config.onReady, onEvent = config.onEvent;
    /**
     * Get data base64
     * @param {string} type A DOMString indicating the image format. The default format type is image/png.
     * @param {number} encoderOptions A Number between 0 and 1 indicating the image quality
     */
    var toDataURL = function (type, encoderOptions) {
        var _a;
        if (type === void 0) { type = 'image/png'; }
        var canvas = (_a = container.current) === null || _a === void 0 ? void 0 : _a.getElementsByTagName('canvas')[0];
        return canvas === null || canvas === void 0 ? void 0 : canvas.toDataURL(type, encoderOptions);
    };
    /**
     * Download Image
     * @param {string} name A name of image
     * @param {string} type A DOMString indicating the image format. The default format type is image/png.
     * @param {number} encoderOptions A Number between 0 and 1 indicating the image quality
     */
    var downloadImage = function (name, type, encoderOptions) {
        if (name === void 0) { name = 'download'; }
        if (type === void 0) { type = 'image/png'; }
        var imageName = name;
        if (name.indexOf('.') === -1) {
            imageName = "".concat(name, ".").concat(type.split('/')[1]);
        }
        var base64 = toDataURL(type, encoderOptions);
        var a = document.createElement('a');
        a.href = base64;
        a.download = imageName;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        a = null;
        return imageName;
    };
    /**
     * JSX TO HTMLElement
     * @param {object} cfg
     * @param {boolean} flag isTooltip
     */
    var processConfig = function (cfg, flag) {
        if (flag === void 0) { flag = false; }
        var keys = Object.keys(cfg);
        var isTooltip = flag;
        keys.forEach(function (key) {
            var current = cfg[key];
            if (key === 'tooltip') {
                isTooltip = true;
            }
            if (isFunction(current) && isValidElement("".concat(current))) {
                cfg[key] = function () {
                    var arg = [];
                    for (var _i = 0; _i < arguments.length; _i++) {
                        arg[_i] = arguments[_i];
                    }
                    return createNode(current.apply(void 0, arg), isTooltip);
                };
            }
            else {
                if (isArray(current)) {
                    current.forEach(function (item) {
                        processConfig(item, isTooltip);
                    });
                }
                else if (isObject(current)) {
                    processConfig(current, isTooltip);
                }
                else {
                    isTooltip = flag;
                }
            }
        });
    };
    useEffect(function () {
        if (chart.current && !isEqual(chartOptions.current, config)) {
            chartOptions.current = cloneDeep(config);
            processConfig(config);
            chart.current.update(config);
            chart.current.render();
        }
    }, [config]);
    useEffect(function () {
        if (!container.current) {
            return function () { return null; };
        }
        if (!chartOptions.current) {
            chartOptions.current = cloneDeep(config);
        }
        processConfig(config);
        var chartInstance = new ChartClass(container.current, __assign({}, config));
        chartInstance.toDataURL = toDataURL;
        chartInstance.downloadImage = downloadImage;
        chartInstance.render();
        chart.current = chartInstance;
        if (onReady) {
            onReady(chartInstance);
        }
        var handler = function (event) {
            if (onEvent) {
                onEvent(chartInstance, event);
            }
        };
        chartInstance.on('*', handler);
        // 组件销毁时销毁图表
        return function () {
            if (chart.current) {
                chart.current.destroy();
                chart.current.off('*', handler);
                chart.current = undefined;
            }
        };
    }, []);
    return {
        chart: chart,
        container: container,
    };
}
