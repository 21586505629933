import { flow, transformOptions } from '../../utils';
/**
 * @param chart
 * @param options
 */
export function adaptor(params) {
    /**
     * 图表差异化处理
     */
    var init = function (params) {
        return params;
    };
    return flow(init, transformOptions)(params);
}
