var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { flow, transformOptions, isArray, set, fieldAdapter, isFunction } from '../../utils';
/**
 * @param chart
 * @param options
 */
export function adaptor(params) {
    /**
     * @description 当 angleField 总算为 0 时，设置默认样式
     * @link https://github.com/ant-design/ant-design-charts/issues/2324
     */
    var emptyData = function (params) {
        var options = params.options;
        var angleField = options.angleField, data = options.data, label = options.label, tooltip = options.tooltip, colorField = options.colorField;
        var getColorValue = fieldAdapter(colorField);
        if (isArray(data) && data.length > 0) {
            var sum = data.reduce(function (a, b) { return a + b[angleField]; }, 0);
            if (sum === 0) {
                var normalization = data.map(function (item) {
                    var _a;
                    return (__assign(__assign({}, item), (_a = {}, _a[angleField] = 1, _a)));
                });
                set(options, 'data', normalization);
                if (label) {
                    set(options, 'label', __assign(__assign({}, label), { formatter: function () { return 0; } }));
                }
                if (tooltip !== false) {
                    if (isFunction(tooltip)) {
                        set(options, 'tooltip', function (arg, index, items) {
                            var _a;
                            return tooltip(__assign(__assign({}, arg), (_a = {}, _a[angleField] = 0, _a)), index, items.map(function (item) {
                                var _a;
                                return (__assign(__assign({}, item), (_a = {}, _a[angleField] = 0, _a)));
                            }));
                        });
                    }
                    else {
                        set(options, 'tooltip', __assign(__assign({}, tooltip), { items: [
                                function (arg, i, d) {
                                    return {
                                        name: getColorValue(arg, i, d),
                                        value: 0,
                                    };
                                },
                            ] }));
                    }
                }
            }
        }
        return params;
    };
    return flow(emptyData, transformOptions)(params);
}
